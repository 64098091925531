<template>
  <div>
    <v-dialog
      scrollable
      persistent
      v-model="visible"
      v-bind:disabled="desabilitado"
      v-on:keydown.esc="fecharJanelaSalvar()"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.padrao
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline">{{ lang("configuracao") }}</span>
          <v-divider class="mx-4" inset horizontal></v-divider>
          <v-btn icon v-on:click="fecharJanelaSalvar()">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-data-table
              class="elevation-0"
              height="600px"
              fixed-header
              hide-default-footer
              v-bind:headers="titulo"
              v-bind:items="proprietario.listConfigProprietarioBEA"
              v-bind:items-per-page="itemPagina"
            >
              <template v-slot:item.valor="{ item }">
                <v-text-field
                  name="namValor+"
                  v-model="item.valor"
                  v-bind:label="lang('valor')"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                ></v-text-field>
              </template>
              <template v-slot:no-data>
                <v-alert v-bind:value="false" color="white" icon="info">{{
                  lang("nenhumRegistroEncontrado")
                }}</v-alert>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <v-spacer />
          <v-btn
            v-on:click="fecharJanelaSalvar()"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click="salvar()"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.salvar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.salvar.icone }}</v-icon
            >{{ lang("salvar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Componentes -->
  </div>
</template>

<script>
import util from "../../util/Util.js";
const ITEM_PAGINA = 5000; // v-data-table

export default {
  components: {},
  mixins: [util.mixUtil, util.mixAlerta],
  props: {
    idProprietario: {
      type: Number,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    utilImp: util,
    desabilitado: false,
    valConfigProprietario: "max:30",
    itemPagina: ITEM_PAGINA,
    titulo: [],
    proprietario: []
  }),
  computed: {},
  watch: {
    visible() {
      if (this.visible) {
        this.abrirJanelaSalvar();
      }
    }
  },
  created() {
    this.iniciar();
  },
  mounted() {},
  methods: {
    iniciar() {
      // Títulos da consulta
      this.titulo = [
        {
          text: this.lang("chave"),
          align: "left",
          value: "chave",
          sortable: true
        },
        {
          text: this.lang("anotacao"),
          align: "left",
          value: "anotacao",
          sortable: true
        },
        {
          text: this.lang("valor"),
          align: "left",
          value: "valor",
          sortable: true
        }
      ];
    },
    abrirJanelaSalvar() {
      this.listar();
    },
    listar() {
      this.desabilitar(true);
      this.limparLista();
      util.srv.proprietario
        .obterProprietario(this.idProprietario)
        .then(response => {
          this.distribuir(response.data);
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },
    distribuir(arrayRegistro) {
      if (arrayRegistro.result !== null) {
        if (arrayRegistro.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
          this.proprietario = arrayRegistro.result;
        }
      } else {
        this.alertInfo(this.lang("nenhumRegistroEncontrado"));
      }
    },

    salvar() {
      this.validar();
    },
    async validar() {
      // if (!await this.$validator.validate('namUnidade')) {
      //   this.alertWarningToast(this.errors.first('namUnidade'))
      //   return
      // }
      this.alterar();
    },
    alterar() {
      this.desabilitar(true);
      util.srv.proprietario
        .alterarProprietario(this.proprietario)
        .then(response => {
          this.desabilitar(false);
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.limparLista();
            this.fecharJanelaSalvar();
            this.listar();
            this.alertSucessToast(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },
    fecharJanelaSalvar() {
      if (!this.desabilitado) {
        this.$emit("visible", false);
      }
    },
    desabilitar(desabilitado) {
      this.desabilitado = desabilitado;
    },
    limpar() {
      this.limparLista();
    },
    limparLista() {
      this.proprietario = [];
    }
  }
};
</script>
